import * as React from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone, faEnvelopeOpen, faMapMarker } from '@fortawesome/free-solid-svg-icons'
import { AnchorLink } from "gatsby-plugin-anchor-links";

import AnimationWrapper from "../components/animationWrapper"
import "./contactTile.css"

const ContactTile = ( { contactData } ) => {

  return (
    <div class="contact-tile-container">
      <AnimationWrapper animationType="fadeInUp">
        <div className="contact-tile">
          <span className="section-subtitle">Skontaktuj się z nami</span>
          <h2 className="section-heading">Zapraszamy do kontaktu</h2>
          <ul className="contact-info-wrapper">
            {contactData.address &&
              <li>
                <FontAwesomeIcon icon={faMapMarker} />
                <AnchorLink to="#location-map-pin" title={contactData.address} />
              </li>
            }
            {contactData.email &&
              <li>
                <FontAwesomeIcon icon={faEnvelopeOpen} />
                <a href={`mailto:${contactData.email}`}>{contactData.email}</a>
              </li>
            }
            {contactData.phone &&
              <li>
                <FontAwesomeIcon icon={faPhone} />
                <a href={`tel:${contactData.phone}`}> {contactData.phone}</a>
              </li>
            }
            {contactData.phone_alt &&
              <li>
                <FontAwesomeIcon icon={faPhone} />
                <a href={`tel:${contactData.phone_alt}`}>
                  {contactData.phone_alt}{contactData.phone_postfix && <>{contactData.phone_postfix}</>}
                </a>
              </li>
            }
          </ul>
        </div>
      </AnimationWrapper>
    </div>
  )
}

export default ContactTile